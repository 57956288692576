@import 'base';

html { font-size: 80%; box-sizing: border-box; }
body { color: #000 !important; background: #fff !important; padding-top: 0; }
.js body {padding: 0;}

.l-Site__skipLinks,
.l-Site__compatWarnings,
.l-Site__header,
.l-Site__footer,
.l-Site__hero,
.l-Page__crumbs,
.l-Page__filter,
.Card__cta,
.Pagination {
	display: none !important;
}
.l-Site__container {
	max-width: unset !important;
	padding: 0 !important;
}
.l-Page__body {
    max-width: unset !important;
}

.o-List__item {
    padding-top: 2.25rem;
    border-top: .0625rem solid #cdcccb;

    &:first-child {
        padding-top: 0;
        border: 0;
    }
}

.Card {
    page-break-inside: avoid;
    padding: 0;
    box-shadow: none;
}

.s-cmsContent {
    img {
        min-width: auto;
        page-break-inside: avoid;
    }
    p {
        page-break-inside: avoid;
    }

    img,
    .captionImage,
    .embed {

        &.left {
            float: left;
            margin-right: vr(2);
        }

        &.leftAlone {
            margin-right: auto;
        }

        &.right {
            float: right;
            margin-left: vr(2);
        }

        &.rightAlone {
            margin-left: auto;
        }

        &.center {
            margin: {
                left: auto;
                right: auto;
            }
        }
    }
}
